<template>
  <CCard>
    <CCardHeader>
      <h4 style="color:#00A8FF">{{ site.name }}</h4>
    </CCardHeader>
    <HistoryMeasure
        ref="historyMeasure"
        :site_guid.sync="site_guid"
        :device_guid.sync="device_guid"
        :device.sync="device"
    />
  </CCard>
</template>

<script>
import HistoryMeasure from '@/views/device/HistoryMeasure';

export default {
  name: 'Summarize',
  components: {
    HistoryMeasure,
  },
  created() {
    this.userInfo = this.$store.state.auth.user_info;
    this.user = this.userInfo.user;
    this.sites = this.userInfo.sites

    var active_site_index = _.get(this.user, "active_site_index", 0)
    this.site_guid = this.user.active_site_guids[active_site_index]
    this.site = _.find(this.sites, {guid:this.site_guid})
  },
  data () {
    return {
      site_guid: undefined,
      device_guid: undefined,
      device: {}
    }
  },
}
</script>

<style scoped>
.card-body {
  flex: 1 1 auto;
  min-height: 1px;
  padding: 0.50rem;
}
</style>